<template>
  <div class="newsInfo">
    <div class="newsInfomation">
      <div class="box m160">
        <div class="content2">
          <p>
            {{ LegalNotices.title }}
          </p>
        </div>

        <div class="content4" v-html="LegalNotices.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "technology",
  data() {
    return {
      LegalNotices: {},
    };
  },
  created() {
    this.$http.get("api.php/Webinfo/getPage").then(
      function (res) {
        var news_info_data = JSON.parse(res.data);
        this.LegalNotices = news_info_data.jsrk;

        console.log(news_info_data);
      },
      function (res) {
        console.log(res.status);
      }
    );
  },
};
</script>

<style lang="less" scoped>
@gilroy_b: "gilroy_bold";
@gilroy_r: "gilroy_regular";
@albb_b: "albb_b";
@albb_r: "albb_r";
@albb_l: "albb_l";
@albb_m: "albb_m";
@color: #97c852;
.newsInfomation {
  background: #fff;
  margin-top: 0.73rem;
  padding-top: 0.9rem;
  .box {
    .content1 {
      font-size: 0.16rem;
      a {
        color: #666;
        transition: 0.4s;
        svg {
          transform: rotate(180deg);
        }
        &:hover {
          color: @color;
        }
      }
    }
    .content2 {
      margin-top: 0.5rem;
      p {
        font-family: @albb_m;
        font-size: 0.32rem;
        line-height: 0.48rem;
        width: 60%;
      }
    }
    .content3 {
      font-family: @gilroy_r;
      font-size: 0.2rem;
      color: #999;
      text-align: right;
      margin-top: -0.3rem;
    }
    .content4 {
      font-size: 0.18rem;
      font-family: @albb_m;
      color: #666;
      line-height: 0.48rem;
      text-align: justify;
      border-top: 1px solid #ddd;
      margin-top: 0.68rem;
      padding-top: 0.68rem;
      padding-bottom: 0.8rem;
      img {
        width: 100%;
      }
    }
  }
}
</style>
